<template>    
     <header class="header slider-fade text-white" data-scroll-index="0">
        <div class="item bg-img" data-overlay-dark="5" v-lazy:background-image="`${publicPath}images/bg3.jpg`">
          <div class="text-left v-middle caption mt-30">
            <div class="container-fluid">
                  <div class="row">
                      <div class="col-xl-8 col-lg-12 col-md-12 mx-auto">
                        <h2>PSD To Any CMS</h2>
                        <p>We Convert PSD to XHTML, Wordpress, Joomla, osCommerce and CMS. </p>
						<router-link :to="{hash:'#home'}" class="btn" title="Get Free Quote"> Get Free QUote </router-link>                        
                      </div>
                  </div>
            </div>      
          </div>
        </div>
    </header>
</template>    
<script>
export default {
    data () {
        return {
            publicPath: process.env.BASE_URL
        }
    }    
}
</script>
